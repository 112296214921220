import React from 'react';
import { Typography, Grid, Button, Card, CardContent } from '@mui/material';

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: '#f4f4f4', color: '#333', padding: '20px 10px' }}>
      {/* Header Section */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="h4" gutterBottom style={{ color: '#00A8FF', lineHeight: '1.4' }}>
          About Us: Your Gateway to the  Engineering Colleges
        </Typography>
        <Typography variant="body1" style={{ color: '#666', lineHeight: '1.6', padding: '0 10px' }}>
          Discover the perfect engineering college tailored to your aspirations. We provide
          comprehensive insights, expert counseling, and guidance for a seamless journey to success.
        </Typography>
      </div>

      {/* Layout with Content and Image */}
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        {/* Left Content */}
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#333' }}>
            Why Choose Us?
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#666', lineHeight: '1.6' }}>
            We simplify the path to your dream engineering college with our dedicated resources and
            personalized guidance.
          </Typography>

          <ul style={{ listStyleType: 'disc', margin: '0 auto', padding: '0 20px', textAlign: 'left', maxWidth: '400px', color: '#666', lineHeight: '1.6' }}>
            <li>Detailed profiles of top engineering colleges</li>
            <li>Expert advice tailored to your goals</li>
            <li>Comprehensive admission process insights</li>
            <li>Career growth and placement assistance</li>
            <li>Latest updates on engineering trends</li>
          </ul>
        </Grid>

        {/* Right Image */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              maxWidth: 400,
              height: 200,
              backgroundImage: 'url("https://svmsolutions.com/wp-content/uploads/2020/01/converting-about-us-page.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '10px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Grid>
      </Grid>

      {/* CTA Button */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          href="#"
          variant="contained"
          sx={{
            backgroundColor: '#00A8FF',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '1rem',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#008CCF',
            },
          }}
        >
          Learn More →
        </Button>
      </div>
    </div>
  );
};

export default AboutUs;
