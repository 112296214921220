import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import logo from '../assets/logo.png';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleNavigation = (url) => {
    window.location.href = url; // Navigate to the specified URL
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navigationItems = [
    { label: 'Home', url: 'https://www.getmycollege.com/' },
    { label: 'About', url: 'https://www.getmycollege.com/about-us' },
    { label: 'Colleges', url: 'https://www.getmycollege.com/colleges' },
    { label: 'Courses', url: 'https://www.getmycollege.com/colleges?state=karnataka&stream=-engineering&courses=b%20e%20computer%20science%20and%20engineering' },
    { label: 'Careers', url: 'https://www.getmycollege.com/careers' },
    { label: 'Contact', url: 'https://www.getmycollege.com/contact-us' },
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#002366', color: '#FFFFFF', boxShadow: 3 }}>
      <Container disableGutters maxWidth={false}>
        {/* Main Toolbar */}
        <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{ height: 50, width: 50, borderRadius: '50%' }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#FFFFFF',
                letterSpacing: 1,
                textTransform: 'uppercase',
                display: { xs: 'none', md: 'block' }, // Hide on small screens
              }}
            >
               Engineering Colleges Bangalore
            </Typography>
          </Box>

          {/* Desktop Navigation Buttons */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {navigationItems.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleNavigation(item.url)}
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  color: '#FFFFFF',
                  '&:hover': {
                    color: '#FFCC00',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease',
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            backgroundColor: '#002366',
            height: '100%',
            color: '#FFFFFF',
            padding: 2,
          }}
          role="presentation"
        >
          {/* Close Button */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer(false)}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: '#FFCC00',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 2,
              color: '#FFCC00',
              textTransform: 'uppercase',
            }}
          >
            Menu
          </Typography>
          <List>
            {navigationItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => handleNavigation(item.url)}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FFCC00',
                      color: '#002366',
                    },
                  }}
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
