import React, { useEffect, useState } from 'react';
import Header from './Header';
import EngineeringCollegesPage from './EngineeringCollegesPage';
import CollegeList from './CollegeList';
import AboutUs from './AboutUs';
import Footer from './Footer';
import AutoPopup from './AutoPopup';

const App = () => {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
  })
  return (
    <div>
      <Header/>
      <EngineeringCollegesPage />
      <CollegeList />
      <AboutUs />
      <Footer />

      <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}
        leadFlag="Organic"
      />

    </div>
  );
};

export default App;
