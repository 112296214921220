import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Box,
  Rating,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled } from "@mui/system";
import { motion } from "framer-motion";

const GradientBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: "100vh",
  background: "white",
  borderRadius: 12,
  boxShadow: "0 10px 15px rgba(0,0,0,0.2)",
  marginBottom: "-10px", // Moves the entire component upwards by 50px
}));

const AnimatedCard = styled(Card)(({ theme }) => ({
  borderRadius: "20px",
  boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.01)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  background: "#003366", // Dark blue color
  color: "#fff",
  fontWeight: "bold",
  borderRadius: 20,
  padding: "10px 20px",
  transition: "transform 0.3s ease, background 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    background: "#002244", // Slightly darker shade on hover
  },
}));

const collegesData = [
  {
    name: "R.V. College of Engineering",
    image: "https://www.highereducationdigest.com/wp-content/uploads/2019/04/Img-3_800x480-4.jpg",
    description: "A premier engineering institution in Bangalore.",
    location: "Mysuru Road, Bangalore",
    rating: 4.7,
    link: "https://www.getmycollege.com/college/bangalore/r-v-institute-of-technology-management",
  },
  {
    name: "BMS College of Engineering",
    image: "https://admissionbackup.com/wp-content/uploads/2017/09/Direct-Admission-BMS-College-of-Engineering.jpg",
    description: "One of the oldest engineering colleges in Bangalore.",
    location: "Basavanagudi, Bangalore",
    rating: 4.6,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "PES University",
    image: "https://pes.edu/wp-content/uploads/2024/02/campus-400x265-1.jpg",
    description: "Known for its excellent academic programs and vibrant campus life.",
    location: "Banashankari 3rd Stage, Bangalore",
    rating: 4.8,
    link: "https://www.getmycollege.com/college/pes-university",
  },
  {
    name: "MS Ramaiah Institute of Technology",
    image: "https://singheducation.co.in/images/CollegeImages/752074eead10ed35a61cc1102305bf2cd06cde.jpg",
    description: "Offers top-tier engineering education with excellent infrastructure.",
    location: "MSR Nagar, Bangalore",
    rating: 4.5,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Dayananda Sagar College of Engineering",
    image: "https://www.bangaloreadmission.com/uploads/1/2/7/9/1279976/9778526_orig.jpg",
    description: "Known for its diverse programs and quality education.",
    location: "Kumaraswamy Layout, Bangalore",
    rating: 4.4,
    link: "https://www.getmycollege.com/college/bangalore/dayananda-sagar-college-of-engineering",
  },
  {
    name: "New Horizon College of Engineering",
    image: "https://newhorizonindia.edu/wp-content/uploads/2023/12/New-Horizon-College-Of-Engineering.webp",
    description: "Renowned for its industry-focused curriculum.",
    location: "Marathahalli, Bangalore",
    rating: 4.3,
    link: "https://www.getmycollege.com/college/bangalore/new-horizon-college-of-engineering",
  },
  {
    name: "Christ University Faculty of Engineering",
    image: "https://cce.edu.in/static/images/home.jpg",
    description: "Blends engineering education with holistic development.",
    location: "Hosur Road, Bangalore",
    rating: 4.6,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "NITTE Meenakshi Institute of Technology",
    image: "https://collegekeeda.com/wp-content/uploads/2023/11/nitte-meenakshi-institute-of-technology.webp",
    description: "Offers comprehensive engineering education with excellent facilities.",
    location: "Yelahanka, Bangalore",
    rating: 4.2,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Amrita School of Engineering",
    image: "https://webfiles.amrita.edu/2022/03/cvBWG84J-amaravati-campus-about.jpg",
    description: "Known for its innovative teaching and research-oriented approach.",
    location: "Kasavanahalli, Bangalore",
    rating: 4.5,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "The Oxford College of Engineering",
    image: "https://images.shiksha.com/mediadata/images/1549360328phpNMtAxX.png",
    description: "One of the top-rated engineering colleges with great campus facilities.",
    location: "Bommanahalli, Bangalore",
    rating: 4.3,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Acharya Institute of Technology",
    image: "https://admissionkaro.com/wp-content/uploads/2023/02/Management-quota-admission-in-Acharya-Institute-of-Technology-Bangalore.jpg",
    description: "A leading institute offering diverse engineering programs.",
    location: "Soladevanahalli, Bangalore",
    rating: 4.4,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Reva University",
    image: "https://www.highereducationdigest.com/wp-content/uploads/2020/01/IMG-9.jpg",
    description: "A university known for its state-of-the-art infrastructure.",
    location: "Yelahanka, Bangalore",
    rating: 4.5,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "CMR Institute of Technology",
    image: "https://media.licdn.com/dms/image/v2/D4D1BAQFj_ZcvIT0pag/company-background_10000/company-background_10000/0/1657040178535/cmrcetofficial_cover?e=2147483647&v=beta&t=AeOQaUzRTZTMm1vD38daOFnrum890xIIUUlojaN6JNk",
    description: "Known for producing skilled engineering graduates.",
    location: "ITPL Main Road, Bangalore",
    rating: 4.3,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Jain University Faculty of Engineering",
    image: "https://set.jainuniversity.ac.in/application/themes/set/assets/images/m-banner.jpg",
    description: "A dynamic university focused on academic excellence.",
    location: "Jayanagar, Bangalore",
    rating: 4.2,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "East West Institute of Technology",
    image: "https://i0.wp.com/www.admissionmba.in/wp-content/uploads/2013/12/EAST-WEST-INSTITUTE-OF-TECHNOLOGY.jpg",
    description: "An institution offering quality education at affordable costs.",
    location: "Magadi Road, Bangalore",
    rating: 4.1,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "T.John Institute of Technology",
    image: "https://images.shiksha.com/mediadata/images/1563772555phpcASoX2.png",
    description: "A renowned institute offering innovative engineering education.",
    location: "Anekal, Bangalore",
    rating: 4.2,
    link: "https://www.getmycollege.com/college/bangalore/t-john-institute-of-technology",
  },
  {
    name: "Sri Sivasubramaniya Nadar College of Engineering",
    image: "https://learnerhunt-assets.s3.us-east-1.amazonaws.com/63cfed7e-b80a-4ee7-8979-4a36e0fc58f3.png",
    description: "Offers a wide variety of engineering programs with top-notch facilities.",
    location: "Rajiv Gandhi Salai, Chennai (near Bangalore)",
    rating: 4.3,
    link: "https://www.getmycollege.com/college/bangalore/sri-sivasubramaniya-nadar-college-of-engineering",
  },
  {
    name: "Kendriya Vidyalaya College of Engineering",
    image: "https://kvgengg.com/wp-content/uploads/2024/09/kvgce.jpg",
    description: "Famous for its faculty and high standard of education.",
    location: "Yelahanka, Bangalore",
    rating: 4.5,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Sahyadri College of Engineering & Management",
    image: "https://www.sahyadri.edu.in/_next/image?url=%2Fimages%2Fhome%2Fcollegeaerial.jpg&w=3840&q=75",
    description: "Offers a blend of excellent engineering education and industry exposure.",
    location: "Mangalore, near Bangalore",
    rating: 4.4,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "MVJ College of Engineering",
    image: "https://upload.wikimedia.org/wikipedia/commons/e/ea/MVJ_College_of_Engineering.jpg",
    description: "A top-rated engineering college with high academic standards.",
    location: "Hoskote, Bangalore",
    rating: 4.3,
    link: "https://www.getmycollege.com/college/bangalore/mvj-college-of-engineering",
  },
  {
    name: "Bangalore Institute of Technology",
    image: "https://media.collegedekho.com/media/img/institute/crawled_images/None/BDGHGFT.jpg?width=640",
    description: "Known for its quality education and infrastructure in engineering fields.",
    location: "K.R. Road, Bangalore",
    rating: 4.5,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "National Institute of Technology Karnataka",
    image: "https://timess3spore.s3.amazonaws.com/ndata/media/College/college_desktop_webp/fdc1cd6decb5c66a367d3ab2d81660991.webp",
    description: "Offers world-class engineering education with cutting-edge research.",
    location: "Surathkal, near Mangalore, Bangalore",
    rating: 4.8,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Vidyavardhaka College of Engineering",
    image: "https://www.highereducationdigest.com/wp-content/uploads/2020/04/Img-3-19.jpg",
    description: "Providing quality education with a focus on engineering and technology.",
    location: "Mysuru, near Bangalore",
    rating: 4.2,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Sir M Visvesvaraya Institute of Technology",
    image: "https://images.shiksha.com/mediadata/images/1489547065php2zxunC.jpeg",
    description: "A prominent institution that provides a strong foundation in engineering.",
    location: "Nandini Layout, Bangalore",
    rating: 4.6,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Rajeev Gandhi Institute of Technology",
    image: "https://collegekeeda.com/wp-content/uploads/2024/01/Rajiv-Gandhi-Institute-of-Technology.webp",
    description: "Offers a modern approach to engineering education.",
    location: "Bangalore",
    rating: 4.2,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "University Visvesvaraya College of Engineering",
    image: "https://uvce.ac.in/img/slides/HomeSlider_10N.JPG",
    description: "One of the oldest and most prestigious engineering colleges in Bangalore.",
    location: "K.R. Market, Bangalore",
    rating: 4.7,
    link: "https://www.getmycollege.com/colleges",
  },
  {
    name: "Global Academy of Technology",
    image: "https://gat.ac.in/images/index-tabination/campus-tab.jpg",
    description: "Focused on providing holistic education to students.",
    location: "Rajarajeshwari Nagar, Bangalore",
    rating: 4.3,
    link: "https://www.getmycollege.com/colleges",
  },
];




const CollegeList = () => {
  const [visibleColleges, setVisibleColleges] = useState(6);
  const [searchQuery, setSearchQuery] = useState("");
  const [bookmarked, setBookmarked] = useState({});

  const showMoreColleges = () => {
    setVisibleColleges((prev) => prev + 6);
  };

  const toggleBookmark = (index) => {
    setBookmarked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const filteredColleges = collegesData.filter((college) =>
    college.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <GradientBox>
      <Typography
        variant="h4"
        align="center"
        sx={{
          color: "black",
          fontWeight: "bold",

          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          fontSize: { xs: "1.5rem", sm: "2rem" },
          mb:5,
          mt: 10,
        }}
      >
       Engineering Colleges in Bangalore
      </Typography>



      <Grid container spacing={4}>
        {filteredColleges.slice(0, visibleColleges).map((college, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            component={motion.div}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
          >
            <AnimatedCard>
              <CardMedia

                component="img"
                height="180"
                image={college.image}
                alt={college.name}
              />
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    sx={{

                      fontWeight: "bold",
                      marginBottom: 1,
                      color: "#333",
                      textShadow: "1px 1px 3px rgba(0,0,0,0.1)",
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                    }}
                  >
                    {college.name}
                  </Typography>
                  <Button
                    onClick={() => toggleBookmark(index)}
                    sx={{ minWidth: 0 }}
                  >
                    {bookmarked[index] ? (
                      <BookmarkIcon color="primary" />
                    ) : (
                      <BookmarkBorderIcon />
                    )}
                  </Button>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: 2, fontSize: { xs: "0.85rem", sm: "1rem" } }}
                >
                  {college.description}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                  <LocationOnIcon color="primary" sx={{ marginRight: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    {college.location}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                  <Rating
                    value={college.rating}
                    precision={0.1}
                    readOnly
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginLeft: 1 }}
                  >
                    {college.rating} / 5
                  </Typography>
                </Box>
                <Divider sx={{ marginBottom: 2 }} />
                <AnimatedButton
                  fullWidth
                  variant="contained"
                  href={college.link}
                  target="_blank"
                >
                  View College
                </AnimatedButton>
              </CardContent>
            </AnimatedCard>
          </Grid>
        ))}
      </Grid>

      {/* Show More Button */}
      {visibleColleges < filteredColleges.length && (
        <Box display="flex" justifyContent="center" sx={{ marginTop: 4 }}>
          <AnimatedButton onClick={showMoreColleges}>Show More</AnimatedButton>
        </Box>
      )}
    </GradientBox>
  );
};

export default CollegeList;
